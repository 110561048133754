import {createApp} from 'vue'
import App from './App.vue'

// 2. 引入组件样式
import 'vant/lib/index.css';
// 1. 引入你需要的组件
import {ImagePreview} from 'vant';
import router from './router/router'
// 引入模块后自动生效
import '@vant/touch-emulator';

createApp(App)
    .use(router)
    .use(ImagePreview)
    .mount('#app')
