import {createRouter, createWebHistory} from 'vue-router';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        //文章详情 带评论的
        {
            path: '/',
            component: () => import('@/components/HelloWorld.vue')
        },
        //文章详情 带评论的
        {
            path: '/tell',
            component: () => import('@/components/tell.vue')
        },
    ],
});

export default router;
